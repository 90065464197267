/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from './layout'
import Card from '../../components/post-card'

export default ({ posts, ...props }) => {
  posts = posts.filter(post => post.date.includes('2000') !== true)
  return (
    <Layout {...props}>
      <main>
        <div sx={{ textAlign: 'center' }}>
          <h1 sx={{ variant: 'text.sectionHeading' }}>Blog</h1>
        </div>
        <ul
          sx={{
            listStyle: 'none',
            display: 'grid',
            textAlign: 'center',
            gridGap: 3,
            gridTemplateColumns: [
              `1fr`,
              'repeat(2, minmax(250px, 1fr))',
              'repeat(3, minmax(250px, 1fr))',
            ],
            gridAutoRows: `1fr`,
            m: 0,
            px: [2, 3, 4],
            py: [2, 3, 4],
          }}
        >
          {posts.map(post => (
            <li
              key={post.id}
              sx={{
                border: '1px solid',
                borderColor: 'green',
                p: [2, 3, 4],
                display: 'grid',
              }}
            >
              <Card post={post} />
            </li>
          ))}
        </ul>
      </main>
    </Layout>
  )
}
